import { DropdownExposedProps } from '@pattern-library/src/basicUI/Dropdowns/DropdownExposed'
import pubgenLogo_dark from 'pattern-library/public/_static/pubgenLogo_dark.svg'
import pubgenLogo_light from 'pattern-library/public/_static/pubgenLogo_light.svg'

import {
  BlockPubArticleBody,
  BlockPubArticleHeader,
  BlockPubAuthorOverview,
  BlockPubSectionMainView,
  BlockPubTagHeader,
  StripePlanFrequency,
  SubscriptionPlan,
} from '@models/types'
import { DEFAULT_ARTICLE_ACCESS, PUBLICATION_INTERNAL_PAGE_SLUGS } from '@shared/constants'

import { createArticleSlug, createAuthorSlug, createSectionSlug, createTagSlug } from '../../../shared/util'
import {
  CreateBlockInput,
  CreateSiteInput,
  ExternalDisplay,
  GetPagePublicationSettingsQuery,
  Paywall,
  StripeSubscriptionStatus,
} from '../graphql/operations'
import { THEME_PALETTE } from '../hoc/withTheme'
import { CreatePageInput } from './../graphql/operations'
import { idGeneratorForAppsync } from './appsync-utils'

// TEMPLATE FOR VISUAL IDENTITY SITE
export const publicationSiteInputCreator = ({ brandId }: { brandId: string }): Omit<CreateSiteInput, 'name'> => {
  const siteId = idGeneratorForAppsync('site')
  // pages
  const pageHomeId = idGeneratorForAppsync('page')
  const pageSubscribeId = idGeneratorForAppsync('page')
  const pageSignInId = idGeneratorForAppsync('page')
  // const pageCheckoutId = idGeneratorForAppsync('page')
  const pageAccountId = idGeneratorForAppsync('page')
  // const pagePaymentsId = idGeneratorForAppsync('page')
  const pageThankYouId = idGeneratorForAppsync('page')
  const pageTermsId = idGeneratorForAppsync('page')
  const pagePrivacyPolicyId = idGeneratorForAppsync('page')
  const pageLatestArticlesId = idGeneratorForAppsync('page')
  const pageSearchId = idGeneratorForAppsync('page')
  const page404Id = idGeneratorForAppsync('page')
  const pageContactUsId = idGeneratorForAppsync('page')
  const pageNewslettersId = idGeneratorForAppsync('page')
  const pageAllTags = idGeneratorForAppsync('page')
  const pageAllSections = idGeneratorForAppsync('page')
  const pageAllAuthors = idGeneratorForAppsync('page')
  const pageSitemapId = idGeneratorForAppsync('page')
  const pageGiftId = idGeneratorForAppsync('page')
  const pageGiftRedeemId = idGeneratorForAppsync('page')

  // PageTemplates
  const mainTemplateId = idGeneratorForAppsync('pageTemplate')
  const mainWithoutEmailCaptureTemplateId = idGeneratorForAppsync('pageTemplate')
  const squeezeTemplateId = idGeneratorForAppsync('pageTemplate')
  const articleTemplateId = idGeneratorForAppsync('pageTemplate')
  const authorTemplateId = idGeneratorForAppsync('pageTemplate')
  const sectionTemplateId = idGeneratorForAppsync('pageTemplate')
  const tagTemplateId = idGeneratorForAppsync('pageTemplate')
  const footerOnlytTemplateId = idGeneratorForAppsync('pageTemplate')

  // TemplateBLocks
  const blockPubFooterId = idGeneratorForAppsync('blockTemplate')
  const blockPubHeaderMenuId = idGeneratorForAppsync('blockTemplate')
  const blockEmailCaptureId = idGeneratorForAppsync('blockTemplate')
  const blockPubNavSqueezeId = idGeneratorForAppsync('blockTemplate')
  const blockPubPaywallArticleId = idGeneratorForAppsync('blockTemplate')
  const blockPubArticleRelatedArticlesId = idGeneratorForAppsync('blockTemplate')
  const blockPubAuthorRelatedArticlesId = idGeneratorForAppsync('blockTemplate')
  const blockPubSectionTopStoriesId = idGeneratorForAppsync('blockTemplate')
  const blockPubSectionRelatedArticlesId = idGeneratorForAppsync('blockTemplate')
  const blockPubTagRelatedArticlesId = idGeneratorForAppsync('blockTemplate')
  const blockPubPromoId = idGeneratorForAppsync('blockTemplate')

  // Home Page
  const blockPubWeatherAdvisoryId = idGeneratorForAppsync('block')
  const blockPubHomeHeroId = idGeneratorForAppsync('block')
  const blockPubLeadArticleId = idGeneratorForAppsync('block')
  const blockPubHomeTopStoriesId = idGeneratorForAppsync('block')
  const blockPubArticlesBySectionId1 = idGeneratorForAppsync('block')
  const blockPubMediaTopStoriesId = idGeneratorForAppsync('block')
  const blockEmailCaptureHomeId = idGeneratorForAppsync('block')
  const blockGoogleAdId1 = idGeneratorForAppsync('block')
  const blockGoogleAdId2 = idGeneratorForAppsync('block')
  const blockPubWeatherId = idGeneratorForAppsync('block')
  const blockPubArticlesBySectionId2 = idGeneratorForAppsync('block')
  const blockPubArticlesBySectionId3 = idGeneratorForAppsync('block')
  const blockPubArticlesBySectionId4 = idGeneratorForAppsync('block')
  const blockPubArticlesBySectionId5 = idGeneratorForAppsync('block')
  const blockPubArticlesBySectionId6 = idGeneratorForAppsync('block')
  const blockPubSectionsTopStoryId = idGeneratorForAppsync('block')
  const blockPubSectionRelatedArticlesEmphasisAuthorsId = idGeneratorForAppsync('block')
  const blockPubExternalReferencesId = idGeneratorForAppsync('block')
  const blockPubBreakingNewsId = idGeneratorForAppsync('block')
  const blockPubAdHeroId = idGeneratorForAppsync('block')

  // Subscribe Page
  const blockPubSubscribeId = idGeneratorForAppsync('block')
  // SignIn Page
  const blockPubSigninId = idGeneratorForAppsync('block')
  // // Checkout Page
  // const blockPubCheckoutId = idGeneratorForAppsync('block')
  // Account Page
  const blockPubAccountId = idGeneratorForAppsync('block')
  // // Payments Page
  // const blockPaymentsId = idGeneratorForAppsync('block')
  // Thank You Page
  const blockPubThankYouId = idGeneratorForAppsync('block')
  // Terms Page
  const blockWysiwygTermsId = idGeneratorForAppsync('block')
  // Privacy Policy Page
  const blockWysiwygPrivacyPolicyId = idGeneratorForAppsync('block')
  // Latest Articles Page
  const blockPubLatestArticlesId = idGeneratorForAppsync('block')
  // Page Search
  const blockPubSearchId = idGeneratorForAppsync('block')
  // Page Contact Us
  const blockFaqId = idGeneratorForAppsync('block')
  const blockWysiwygContactUsId = idGeneratorForAppsync('block')
  const blockCardsId = idGeneratorForAppsync('block')
  const blockCoverImageId = idGeneratorForAppsync('block')
  // Newsletters page
  const blockNewslettersId = idGeneratorForAppsync('block')
  // All Tags page
  const blockAllTagsId = idGeneratorForAppsync('block')
  // All Sections page
  const blockAllSectionsId = idGeneratorForAppsync('block')
  // All Authors page
  const blockAllAuthorsId = idGeneratorForAppsync('block')
  // Sitemap page
  const blockPubSitemapId = idGeneratorForAppsync('block')
  // Page 404
  const blockPub404Id = idGeneratorForAppsync('block')
  // Gift Page
  const blockPubGiftId = idGeneratorForAppsync('block')
  // Gift Redeem Page
  const blockPubGiftRedeemId = idGeneratorForAppsync('block')

  return {
    brandId,
    id: siteId,
    siteCategory: 'Publication',
    createPageTemplates: [
      {
        id: mainTemplateId,
        blockVariants: [],
        blockTemplates: [
          {
            slot: 'header',
            ids: [blockPubHeaderMenuId],
          },
          {
            slot: 'footer',
            ids: [blockEmailCaptureId, blockPubFooterId, blockPubPromoId],
          },
        ], // all the blocks that will be in template
        createBlockTemplates: [
          {
            id: blockPubHeaderMenuId,
            siteId,
            blockCategory: 'PubHeaderMenu',
            componentId: 'blockPubHeaderMenu',
          },
          {
            id: blockEmailCaptureId,
            siteId,
            blockCategory: 'EmailCapture',
            componentId: 'blockEmailCapture',
          },
          {
            id: blockPubFooterId,
            siteId,
            blockCategory: 'PubFooter',
            componentId: 'blockPubFooter',
          },
          {
            id: blockPubPromoId,
            siteId,
            blockCategory: 'PubPromo',
            componentId: 'blockPubPromo',
          },
        ],
        componentId: 'pageTemplateVertical',
        pageTemplateCategory: 'PublicationMain',
        siteId,
      },
      {
        id: mainWithoutEmailCaptureTemplateId,
        blockVariants: [],
        blockTemplates: [
          {
            slot: 'header',
            ids: [blockPubHeaderMenuId],
          },
          {
            slot: 'footer',
            ids: [blockPubFooterId, blockPubPromoId],
          },
        ],
        createBlockTemplates: [],
        componentId: 'pageTemplateVertical',
        pageTemplateCategory: 'PublicationMainWithoutEmailCapture',
        siteId,
      },
      {
        id: articleTemplateId,
        blockVariants: [],
        blockTemplates: [
          {
            slot: 'header',
            ids: [blockPubHeaderMenuId],
          },
          {
            slot: 'footer',
            ids: [blockPubArticleRelatedArticlesId, blockEmailCaptureId, blockPubFooterId, blockPubPaywallArticleId],
          },
        ], // all the blocks that will be in template
        createBlockTemplates: [
          {
            id: blockPubArticleRelatedArticlesId,
            siteId,
            blockCategory: 'PubArticleRelatedArticles',
            componentId: 'blockPubArticleRelatedArticles',
          },
          {
            id: blockPubPaywallArticleId,
            siteId,
            blockCategory: 'PubPaywallArticle',
            componentId: 'blockPubPaywallArticle',
          },
        ],
        componentId: 'pageTemplateVertical',
        pageTemplateCategory: 'PublicationArticle',
        siteId,
      },
      {
        id: authorTemplateId,
        blockVariants: [],
        blockTemplates: [
          {
            slot: 'header',
            ids: [blockPubHeaderMenuId],
          },
          {
            slot: 'footer',
            ids: [blockPubAuthorRelatedArticlesId, blockEmailCaptureId, blockPubFooterId, blockPubPromoId],
          },
        ], // all the blocks that will be in template
        createBlockTemplates: [
          {
            id: blockPubAuthorRelatedArticlesId,
            siteId,
            blockCategory: 'PubAuthorRelatedArticles',
            componentId: 'blockPubAuthorRelatedArticles',
          },
        ],
        componentId: 'pageTemplateVertical',
        pageTemplateCategory: 'PublicationAuthor',
        siteId,
      },
      {
        id: sectionTemplateId,
        blockVariants: [],
        blockTemplates: [
          {
            slot: 'header',
            ids: [blockPubHeaderMenuId],
          },
          {
            slot: 'footer',
            ids: [
              blockPubSectionTopStoriesId,
              blockPubSectionRelatedArticlesId,
              blockEmailCaptureId,
              blockPubFooterId,
              blockPubPromoId,
            ],
          },
        ], // all the blocks that will be in template
        createBlockTemplates: [
          {
            id: blockPubSectionTopStoriesId,
            siteId,
            blockCategory: 'PubSectionTopStories',
            componentId: 'blockPubSectionTopStories',
          },
          {
            id: blockPubSectionRelatedArticlesId,
            siteId,
            blockCategory: 'PubSectionRelatedArticles',
            componentId: 'blockPubSectionRelatedArticles',
          },
        ],
        componentId: 'pageTemplateVertical',
        pageTemplateCategory: 'PublicationSection',
        siteId,
      },
      {
        id: tagTemplateId,
        blockVariants: [],
        blockTemplates: [
          {
            slot: 'header',
            ids: [blockPubHeaderMenuId],
          },
          {
            slot: 'footer',
            ids: [blockPubTagRelatedArticlesId, blockEmailCaptureId, blockPubFooterId, blockPubPromoId],
          },
        ], // all the blocks that will be in template
        createBlockTemplates: [
          {
            id: blockPubTagRelatedArticlesId,
            siteId,
            blockCategory: 'PubTagRelatedArticles',
            componentId: 'blockPubTagRelatedArticles',
          },
        ],
        componentId: 'pageTemplateVertical',
        pageTemplateCategory: 'PublicationTag',
        siteId,
      },
      {
        id: squeezeTemplateId,
        blockVariants: [],
        blockTemplates: [
          {
            slot: 'header',
            ids: [blockPubNavSqueezeId],
          },
          {
            slot: 'footer',
            ids: [blockPubFooterId],
          },
        ],
        createBlockTemplates: [
          {
            id: blockPubNavSqueezeId,
            siteId,
            blockCategory: 'PubNavSqueeze',
            componentId: 'blockPubNavSqueeze',
          },
        ],
        componentId: 'pageTemplateVertical',
        pageTemplateCategory: 'PublicationSqueeze',
        siteId,
      },
      {
        id: footerOnlytTemplateId,
        blockVariants: [],
        blockTemplates: [
          {
            slot: 'footer',
            ids: [blockPubFooterId],
          },
        ],
        createBlockTemplates: [],
        componentId: 'pageTemplateVertical',
        pageTemplateCategory: 'FooterOnly',
        siteId,
      },
    ],
    createPages: [
      {
        templateId: mainTemplateId,
        slug: PUBLICATION_INTERNAL_PAGE_SLUGS.home,
        id: pageHomeId,
        siteId,
        pageCategory: 'PublicationHome',
        localBlockSlots: [
          {
            slot: 'top',
            ids: [blockPubWeatherAdvisoryId, blockPubBreakingNewsId, blockPubAdHeroId],
          },
          {
            slot: 'main',
            ids: [
              blockPubHomeHeroId,
              blockPubLeadArticleId,
              blockPubHomeTopStoriesId,
              blockPubArticlesBySectionId1,
              blockGoogleAdId1,
              blockPubMediaTopStoriesId,
              blockPubSectionsTopStoryId,
              blockEmailCaptureHomeId,
              blockPubWeatherId,
              blockPubArticlesBySectionId2,
              blockPubSectionRelatedArticlesEmphasisAuthorsId,
              blockGoogleAdId2,
              blockPubArticlesBySectionId3,
              blockPubArticlesBySectionId4,
              blockPubArticlesBySectionId5,
              blockPubArticlesBySectionId6,
              blockPubExternalReferencesId,
            ],
          },
        ],
        createLocalblocks: [
          {
            id: blockPubWeatherAdvisoryId,
            pageId: pageHomeId,
            blockCategory: 'PubWeatherAdvisory',
            componentId: 'blockPubWeatherAdvisory',
            externalBlockIds: [],
          },
          {
            id: blockPubBreakingNewsId,
            pageId: pageHomeId,
            blockCategory: 'PubBreakingNews',
            componentId: 'blockPubBreakingNews',
            externalBlockIds: [],
          },
          {
            id: blockPubAdHeroId,
            pageId: pageHomeId,
            blockCategory: 'PubAdHero',
            componentId: 'blockPubAdHero',
            externalBlockIds: [],
          },
          {
            id: blockPubHomeHeroId,
            pageId: pageHomeId,
            blockCategory: 'PubHomeHero',
            componentId: 'blockPubHomeHero',
            externalBlockIds: [],
          },
          {
            id: blockPubLeadArticleId,
            pageId: pageHomeId,
            blockCategory: 'PubLeadArticle',
            componentId: 'blockPubLeadArticle',
            externalBlockIds: [],
          },
          {
            id: blockPubSectionsTopStoryId,
            pageId: pageHomeId,
            blockCategory: 'PubSectionsTopStory',
            componentId: 'blockPubSectionsTopStory',
            externalBlockIds: [],
          },
          {
            id: blockPubHomeTopStoriesId,
            pageId: pageHomeId,
            blockCategory: 'PubHomeTopStories',
            componentId: 'blockPubHomeTopStories',
            externalBlockIds: [],
          },
          {
            id: blockPubArticlesBySectionId1,
            pageId: pageHomeId,
            blockCategory: 'PubArticlesBySection',
            componentId: 'blockPubArticlesBySection',
            externalBlockIds: [],
          },
          {
            id: blockGoogleAdId1,
            pageId: pageHomeId,
            blockCategory: 'GoogleAd',
            componentId: 'blockGoogleAd',
            externalBlockIds: [],
          },
          {
            id: blockPubMediaTopStoriesId,
            pageId: pageHomeId,
            blockCategory: 'PubMediaTopStories',
            componentId: 'blockPubMediaTopStories',
            externalBlockIds: [],
          },
          {
            id: blockEmailCaptureHomeId,
            pageId: pageHomeId,
            blockCategory: 'EmailCapture',
            componentId: 'blockEmailCapture',
            externalBlockIds: [],
          },
          {
            id: blockGoogleAdId2,
            pageId: pageHomeId,
            blockCategory: 'GoogleAd',
            componentId: 'blockGoogleAd',
            externalBlockIds: [],
          },
          {
            id: blockPubWeatherId,
            pageId: pageHomeId,
            blockCategory: 'PubWeather',
            componentId: 'blockPubWeather',
            externalBlockIds: [],
          },
          {
            id: blockPubArticlesBySectionId2,
            pageId: pageHomeId,
            blockCategory: 'PubArticlesBySection',
            componentId: 'blockPubArticlesBySection',
            externalBlockIds: [],
            data: {
              type: 'BlockPubArticlesBySection',
              BlockPubArticlesBySection: {
                hideDivider: true,
              },
            },
          },
          {
            id: blockPubSectionRelatedArticlesEmphasisAuthorsId,
            pageId: pageHomeId,
            blockCategory: 'PubSectionRelatedArticlesEmphasisAuthors',
            componentId: 'blockPubSectionRelatedArticlesEmphasisAuthors',
            externalBlockIds: [],
          },
          {
            id: blockPubArticlesBySectionId3,
            pageId: pageHomeId,
            blockCategory: 'PubArticlesBySection',
            componentId: 'blockPubArticlesBySection',
            externalBlockIds: [],
            data: {
              type: 'BlockPubArticlesBySection',
              BlockPubArticlesBySection: {
                hideDivider: true,
              },
            },
          },
          {
            id: blockPubArticlesBySectionId4,
            pageId: pageHomeId,
            blockCategory: 'PubArticlesBySection',
            componentId: 'blockPubArticlesBySection',
            externalBlockIds: [],
          },
          {
            id: blockPubArticlesBySectionId5,
            pageId: pageHomeId,
            blockCategory: 'PubArticlesBySection',
            componentId: 'blockPubArticlesBySection',
            externalBlockIds: [],
          },
          {
            id: blockPubArticlesBySectionId6,
            pageId: pageHomeId,
            blockCategory: 'PubArticlesBySection',
            componentId: 'blockPubArticlesBySection',
            externalBlockIds: [],
          },
          {
            id: blockPubExternalReferencesId,
            pageId: pageHomeId,
            blockCategory: 'PubExternalReferences',
            componentId: 'blockPubExternalReferences',
            externalBlockIds: [],
          },
        ],
      },
      {
        templateId: squeezeTemplateId,
        slug: PUBLICATION_INTERNAL_PAGE_SLUGS.subscribe,
        id: pageSubscribeId,
        siteId,
        pageCategory: 'PublicationSubscribe',
        localBlockSlots: [
          {
            slot: 'main',
            ids: [blockPubSubscribeId],
          },
        ],
        createLocalblocks: [
          {
            id: blockPubSubscribeId,
            pageId: pageSubscribeId,
            blockCategory: 'PubSubscribe',
            componentId: 'blockPubSubscribe',
            externalBlockIds: [],
          },
        ],
      },
      {
        templateId: squeezeTemplateId,
        slug: PUBLICATION_INTERNAL_PAGE_SLUGS.signIn,
        id: pageSignInId,
        siteId,
        pageCategory: 'PublicationSignIn',
        localBlockSlots: [
          {
            slot: 'main',
            ids: [blockPubSigninId],
          },
        ],
        createLocalblocks: [
          {
            id: blockPubSigninId,
            pageId: pageSignInId,
            blockCategory: 'PubSignIn',
            componentId: 'blockPubSignIn',
            externalBlockIds: [],
          },
        ],
      },
      // {
      //   templateId: squeezeTemplateId,
      //   slug: '/checkout/', // This page is not currently used in the Front-End. Keeping it in case we need it in the future
      //   id: pageCheckoutId,
      //   siteId,
      //   pageCategory: 'PublicationCheckout',
      //   localBlockSlots: [
      //     {
      //       slot: 'main',
      //       ids: [blockPubCheckoutId],
      //     },
      //   ],
      //   createLocalblocks: [
      //     {
      //       id: blockPubCheckoutId,
      //       pageId: pageCheckoutId,
      //       blockCategory: 'PubCheckout',
      //       componentId: 'blockPubCheckout',
      //
      //       externalBlockIds: [],
      //     },
      //   ],
      // },
      {
        templateId: squeezeTemplateId,
        slug: PUBLICATION_INTERNAL_PAGE_SLUGS.account,
        id: pageAccountId,
        siteId,
        pageCategory: 'PublicationAccount',
        localBlockSlots: [
          {
            slot: 'main',
            ids: [blockPubAccountId],
          },
        ],
        createLocalblocks: [
          {
            id: blockPubAccountId,
            pageId: pageAccountId,
            blockCategory: 'PubAccount',
            componentId: 'blockPubAccount',
            externalBlockIds: [],
          },
        ],
      },
      // {
      //   templateId: squeezeTemplateId,
      //   slug: '/payments/', // Not use right in the UI
      //   id: pagePaymentsId,
      //   siteId,
      //   pageCategory: 'PublicationPayments',
      //   localBlockSlots: [
      //     {
      //       slot: 'main',
      //       ids: [blockPaymentsId],
      //     },
      //   ],
      //   createLocalblocks: [
      //     {
      //       id: blockPaymentsId,
      //       pageId: pagePaymentsId,
      //       blockCategory: 'PubPayments',
      //       componentId: 'blockPubPayments',
      //
      //       externalBlockIds: [],
      //     },
      //   ],
      // },
      {
        templateId: squeezeTemplateId,
        slug: PUBLICATION_INTERNAL_PAGE_SLUGS.thankYou,
        id: pageThankYouId,
        siteId,
        pageCategory: 'PublicationThankYou',
        localBlockSlots: [
          {
            slot: 'main',
            ids: [blockPubThankYouId],
          },
        ],
        createLocalblocks: [
          {
            id: blockPubThankYouId,
            pageId: pageThankYouId,
            blockCategory: 'PubThankYou',
            componentId: 'blockPubThankYou',
            externalBlockIds: [],
          },
        ],
      },
      {
        templateId: mainTemplateId,
        slug: PUBLICATION_INTERNAL_PAGE_SLUGS.search,
        id: pageSearchId,
        siteId,
        pageCategory: 'PublicationSearch',
        localBlockSlots: [
          {
            slot: 'main',
            ids: [blockPubSearchId],
          },
        ],
        createLocalblocks: [
          {
            id: blockPubSearchId,
            pageId: pageSearchId,
            blockCategory: 'PubSearch',
            componentId: 'blockPubSearch',
            externalBlockIds: [],
          },
        ],
      },
      {
        templateId: mainTemplateId,
        slug: PUBLICATION_INTERNAL_PAGE_SLUGS.allTags,
        id: pageAllTags,
        siteId,
        pageCategory: 'PublicationAllTags',
        localBlockSlots: [
          {
            slot: 'main',
            ids: [blockAllTagsId],
          },
        ],
        createLocalblocks: [
          {
            id: blockAllTagsId,
            pageId: pageAllTags,
            blockCategory: 'PubAllTags',
            componentId: 'blockPubAllTags',
            externalBlockIds: [],
          },
        ],
      },
      {
        templateId: mainTemplateId,
        slug: PUBLICATION_INTERNAL_PAGE_SLUGS.allSections,
        id: pageAllSections,
        siteId,
        pageCategory: 'PublicationAllSections',
        localBlockSlots: [
          {
            slot: 'main',
            ids: [blockAllSectionsId],
          },
        ],
        createLocalblocks: [
          {
            id: blockAllSectionsId,
            pageId: pageAllSections,
            blockCategory: 'PubAllSections',
            componentId: 'blockPubAllSections',
            externalBlockIds: [],
          },
        ],
      },
      {
        templateId: mainTemplateId,
        slug: PUBLICATION_INTERNAL_PAGE_SLUGS.allWriters,
        id: pageAllAuthors,
        siteId,
        pageCategory: 'PublicationAllAuthors',
        localBlockSlots: [
          {
            slot: 'main',
            ids: [blockAllAuthorsId],
          },
        ],
        createLocalblocks: [
          {
            id: blockAllAuthorsId,
            pageId: pageAllAuthors,
            blockCategory: 'PubAllAuthors',
            componentId: 'blockPubAllAuthors',
            externalBlockIds: [],
          },
        ],
      },
      {
        templateId: mainWithoutEmailCaptureTemplateId,
        slug: PUBLICATION_INTERNAL_PAGE_SLUGS.newsletters,
        id: pageNewslettersId,
        siteId,
        pageCategory: 'PublicationNewsletters',
        localBlockSlots: [
          {
            slot: 'main',
            ids: [blockNewslettersId],
          },
        ],
        createLocalblocks: [
          {
            id: blockNewslettersId,
            pageId: pageNewslettersId,
            blockCategory: 'PubNewsletters',
            componentId: 'blockPubNewsletters',
            externalBlockIds: [],
          },
        ],
      },
      {
        templateId: mainTemplateId,
        slug: PUBLICATION_INTERNAL_PAGE_SLUGS.contactUs,
        id: pageContactUsId,
        siteId,
        pageCategory: 'PublicationContactUs',
        localBlockSlots: [
          {
            slot: 'main',
            ids: [blockCoverImageId, blockWysiwygContactUsId, blockCardsId, blockFaqId],
          },
        ],
        createLocalblocks: [
          {
            id: blockCoverImageId,
            pageId: pageContactUsId,
            blockCategory: 'CoverImage',
            componentId: 'blockCoverImage',
            externalBlockIds: [],
          },
          {
            id: blockWysiwygContactUsId,
            pageId: pageContactUsId,
            blockCategory: 'Wysiwyg',
            componentId: 'blockWysiwyg',
            externalBlockIds: [],
          },
          {
            id: blockCardsId,
            pageId: pageContactUsId,
            blockCategory: 'Cards',
            componentId: 'blockCards',
            externalBlockIds: [],
          },
          {
            id: blockFaqId,
            pageId: pageContactUsId,
            blockCategory: 'Faq',
            componentId: 'blockFaq',
            externalBlockIds: [],
          },
        ],
      },
      {
        templateId: mainTemplateId,
        slug: PUBLICATION_INTERNAL_PAGE_SLUGS.terms,
        id: pageTermsId,
        siteId,
        pageCategory: 'PublicationTerms',
        localBlockSlots: [
          {
            slot: 'main',
            ids: [blockWysiwygTermsId],
          },
        ],
        createLocalblocks: [
          {
            id: blockWysiwygTermsId,
            pageId: pageTermsId,
            blockCategory: 'Wysiwyg',
            componentId: 'blockWysiwyg',
            externalBlockIds: [],
          },
        ],
      },
      {
        templateId: mainTemplateId,
        slug: PUBLICATION_INTERNAL_PAGE_SLUGS.privacyPolicy,
        id: pagePrivacyPolicyId,
        siteId,
        pageCategory: 'PublicationPrivacyPolicy',
        localBlockSlots: [
          {
            slot: 'main',
            ids: [blockWysiwygPrivacyPolicyId],
          },
        ],
        createLocalblocks: [
          {
            id: blockWysiwygPrivacyPolicyId,
            pageId: pagePrivacyPolicyId,
            blockCategory: 'Wysiwyg',
            componentId: 'blockWysiwyg',
            externalBlockIds: [],
          },
        ],
      },
      {
        templateId: mainTemplateId,
        slug: PUBLICATION_INTERNAL_PAGE_SLUGS.latestStories,
        id: pageLatestArticlesId,
        siteId,
        pageCategory: 'PublicationLatestArticles',
        localBlockSlots: [
          {
            slot: 'main',
            ids: [blockPubLatestArticlesId],
          },
        ],
        createLocalblocks: [
          {
            id: blockPubLatestArticlesId,
            pageId: pageLatestArticlesId,
            blockCategory: 'PubLatestArticles',
            componentId: 'blockPubLatestArticles',
            externalBlockIds: [],
          },
        ],
      },
      {
        templateId: mainTemplateId,
        slug: PUBLICATION_INTERNAL_PAGE_SLUGS.sitemap,
        id: pageSitemapId,
        siteId,
        pageCategory: 'PublicationSitemap',
        localBlockSlots: [
          {
            slot: 'main',
            ids: [blockPubSitemapId],
          },
        ],
        createLocalblocks: [
          {
            id: blockPubSitemapId,
            pageId: pageSitemapId,
            blockCategory: 'PubSitemap',
            componentId: 'blockPubSitemap',
            externalBlockIds: [],
          },
        ],
      },
      {
        templateId: footerOnlytTemplateId,
        slug: PUBLICATION_INTERNAL_PAGE_SLUGS.gift,
        id: pageGiftId,
        siteId,
        pageCategory: 'PublicationGift',
        localBlockSlots: [
          {
            slot: 'main',
            ids: [blockPubGiftId],
          },
        ],
        createLocalblocks: [
          {
            id: blockPubGiftId,
            pageId: pageGiftId,
            blockCategory: 'PubGift',
            componentId: 'blockPubGift',
            externalBlockIds: [],
          },
        ],
      },
      {
        templateId: squeezeTemplateId,
        slug: PUBLICATION_INTERNAL_PAGE_SLUGS.giftRedeem,
        id: pageGiftRedeemId,
        siteId,
        pageCategory: 'PublicationGiftRedeem',
        localBlockSlots: [
          {
            slot: 'main',
            ids: [blockPubGiftRedeemId],
          },
        ],
        createLocalblocks: [
          {
            id: blockPubGiftRedeemId,
            pageId: pageGiftRedeemId,
            blockCategory: 'PubGiftRedeem',
            componentId: 'blockPubGiftRedeem',
            externalBlockIds: [],
          },
        ],
      },
      {
        templateId: mainTemplateId,
        slug: PUBLICATION_INTERNAL_PAGE_SLUGS.notFound,
        id: page404Id,
        siteId,
        pageCategory: 'Publication404',
        localBlockSlots: [
          {
            slot: 'main',
            ids: [blockPub404Id],
          },
        ],
        createLocalblocks: [
          {
            id: blockPub404Id,
            pageId: page404Id,
            blockCategory: 'Pub404',
            componentId: 'blockPub404',
            externalBlockIds: [],
          },
        ],
      },
    ],
  }
}

export const createAuthorInput = ({
  siteId,
  templateId,
}: {
  siteId: string
  templateId: string
}): Omit<CreatePageInput, 'slug'> => {
  const pageId = idGeneratorForAppsync('page')
  const blockPubAuthorOverviewId = idGeneratorForAppsync('block')

  return {
    templateId,
    id: pageId,
    siteId,
    pageCategory: 'PublicationAuthor',
    localBlockSlots: [
      {
        slot: 'main',
        ids: [blockPubAuthorOverviewId],
      },
    ],
    createLocalblocks: [
      {
        id: blockPubAuthorOverviewId,
        pageId,
        blockCategory: 'PubAuthorOverview',
        componentId: 'blockPubAuthorOverview',

        externalBlockIds: [],
      },
    ],
  }
}

export const createAuthorPageInput = ({
  siteId,
  templateId,
  author,
  blockPubAuthorHeaderId,
  data,
}: {
  siteId: string
  templateId: string
  author: string
  blockPubAuthorHeaderId?: string
  data: BlockPubAuthorOverview
}): CreatePageInput => {
  const pageId = idGeneratorForAppsync('page')
  const _blockPubAuthorHeaderId = blockPubAuthorHeaderId || idGeneratorForAppsync('block')
  const slug = createAuthorSlug({ author })

  return {
    slug,
    templateId,
    id: pageId,
    siteId,
    pageCategory: 'PublicationAuthor',
    localBlockSlots: [
      {
        slot: 'main',
        ids: [_blockPubAuthorHeaderId],
      },
    ],
    createLocalblocks: [
      {
        id: _blockPubAuthorHeaderId,
        pageId,
        blockCategory: 'PubAuthorOverview',
        componentId: 'blockPubAuthorOverview',
        data: { type: 'BlockPubAuthorOverview', BlockPubAuthorOverview: data },
        externalBlockIds: [],
      },
    ],
  }
}

export const createSectionPageInput = ({
  siteId,
  templateId,
  section,
  blockPubSectionHeaderId,
  relatedTags,
  content,
  horizontal1HeaderScriptOverride,
  horizLazyLoadArticleScript,
  verticalHeaderScriptOverride,
}: {
  siteId: string
  templateId: string
  section: string
  blockPubSectionHeaderId?: string
  relatedTags?: BlockPubSectionMainView['relatedTags']
  content: BlockPubSectionMainView['content']
  horizontal1HeaderScriptOverride: BlockPubSectionMainView['horizontal1HeaderScriptOverride']
  horizLazyLoadArticleScript: BlockPubSectionMainView['horizLazyLoadArticleScript']
  verticalHeaderScriptOverride: BlockPubSectionMainView['verticalHeaderScriptOverride']
}): CreatePageInput => {
  const pageId = idGeneratorForAppsync('page')
  const _blockPubSectionHeaderId = blockPubSectionHeaderId || idGeneratorForAppsync('block')
  const slug = createSectionSlug({ section })
  const data: BlockPubSectionMainView = {
    name: section,
    relatedTags,
    content,
    horizontal1HeaderScriptOverride,
    horizLazyLoadArticleScript,
    verticalHeaderScriptOverride,
  }

  return {
    slug,
    templateId,
    id: pageId,
    siteId,
    pageCategory: 'PublicationSection',
    localBlockSlots: [
      {
        slot: 'main',
        ids: [_blockPubSectionHeaderId],
      },
    ],
    createLocalblocks: [
      {
        id: _blockPubSectionHeaderId,
        pageId,
        blockCategory: 'PubSectionMain',
        componentId: 'blockPubSectionMain',
        data: { type: 'BlockPubSectionMainView', BlockPubSectionMainView: data },
        externalBlockIds: relatedTags || [],
      },
    ],
  }
}

export const createTagPageInput = ({
  siteId,
  templateId,
  tag,
  blockPubTagHeaderHeaderId,
  content,
  horizontal1HeaderScriptOverride,
  horizLazyLoadArticleScript,
  verticalHeaderScriptOverride,
}: {
  siteId: string
  templateId: string
  tag: string
  content?: BlockPubTagHeader['content']
  blockPubTagHeaderHeaderId?: string
  horizontal1HeaderScriptOverride: BlockPubTagHeader['horizontal1HeaderScriptOverride']
  horizLazyLoadArticleScript: BlockPubTagHeader['horizLazyLoadArticleScript']
  verticalHeaderScriptOverride: BlockPubTagHeader['verticalHeaderScriptOverride']
}): CreatePageInput => {
  const pageId = idGeneratorForAppsync('page')
  const _blockPubTagHeaderId = blockPubTagHeaderHeaderId || idGeneratorForAppsync('block')
  const slug = createTagSlug({ tag })
  const data: BlockPubTagHeader = {
    name: tag,
    content,
    horizontal1HeaderScriptOverride,
    horizLazyLoadArticleScript,
    verticalHeaderScriptOverride,
  }

  return {
    slug,
    templateId,
    id: pageId,
    siteId,
    pageCategory: 'PublicationTag',
    localBlockSlots: [
      {
        slot: 'main',
        ids: [_blockPubTagHeaderId],
      },
    ],
    createLocalblocks: [
      {
        id: _blockPubTagHeaderId,
        pageId,
        blockCategory: 'PubTagHeader',
        componentId: 'blockPubTagHeader',
        data: { type: 'BlockPubTagHeader', BlockPubTagHeader: data },
        externalBlockIds: [],
      },
    ],
  }
}

export const createArticlePageInput = ({
  siteId,
  templateId,
  articleTitle,
  sectionName,
  pubArticleHeaderDataObject,
  pubArticleHeaderExternalBlockIds,
  pubArticleBodyDataObject,
  paywall = DEFAULT_ARTICLE_ACCESS,
}: {
  siteId: string
  templateId: string
  articleTitle: string
  sectionName?: string | null
  paywall?: Paywall
  pubArticleHeaderDataObject: BlockPubArticleHeader
  pubArticleHeaderExternalBlockIds: CreateBlockInput['externalBlockIds']
  pubArticleBodyDataObject: BlockPubArticleBody
}): CreatePageInput => {
  const pageId = idGeneratorForAppsync('page')
  const blockPubArticleHeaderId = idGeneratorForAppsync('block')
  const blockPubArticleBodyId = idGeneratorForAppsync('block')
  const slug = createArticleSlug({ articleTitle, sectionName, id: pageId.substring(5, 13) })

  return {
    paywall,
    slug,
    templateId,
    id: pageId,
    siteId,
    pageCategory: 'PublicationArticle',
    localBlockSlots: [
      {
        slot: 'main',
        ids: [blockPubArticleHeaderId, blockPubArticleBodyId],
      },
    ],
    createLocalblocks: [
      {
        id: blockPubArticleHeaderId,
        pageId,
        blockCategory: 'PubArticleHeader',
        componentId: 'blockPubArticleHeader',
        data: { type: 'BlockPubArticleHeader', BlockPubArticleHeader: pubArticleHeaderDataObject },
        externalBlockIds: pubArticleHeaderExternalBlockIds,
      },
      {
        id: blockPubArticleBodyId,
        pageId,
        blockCategory: 'PubArticleBody',
        componentId: 'blockPubArticleBody',
        data: { type: 'BlockPubArticleBody', BlockPubArticleBody: pubArticleBodyDataObject },
        externalBlockIds: [],
      },
    ],
  }
}

// function to remove key __typename from any deeply nested object or deeply nested arrays of objects
export const removeTypename = (obj: any): any => {
  if (obj === null || obj === undefined) {
    return obj
  } else if (Array.isArray(obj)) {
    return obj.map(item => removeTypename(item))
  } else if (typeof obj === 'object') {
    const newObj = {}
    Object.keys(obj).forEach(key => {
      if (key !== '__typename') {
        newObj[key] = removeTypename(obj[key])
      }
    })
    return newObj
  } else {
    return obj
  }
}

export const DEFAULT_PUBLICATION_TIMEZONE = 'GMT-05:00' // 'America/New_York'

export const PAYWALL_OPTIONS_FOR_DD_EXPOSED: { key: Paywall; label: string }[] = [
  { key: 'Metered', label: 'Metered' },
  { key: 'Public', label: 'Free for all' },
  { key: 'Subscribed', label: 'Subscribed Only' },
]

export const EXTERNAL_DISPLAY_OPTIONS_FOR_DD_EXPOSED: { key: ExternalDisplay; label: string }[] = [
  { key: 'default', label: 'Default' },
  { key: 'hero', label: 'Hero' },
  { key: 'lead', label: 'Lead' },
  { key: 'topStory', label: 'Top Story' },
]

// const ExternalTypeOptions: BlockPubArticleHeader['type'][] = ['default', 'sponsored', 'paid']
export const EXTERNAL_TYPE_OPTIONS_FOR_DD_EXPOSED: { key: BlockPubArticleHeader['type']; label: string }[] = [
  { key: 'default', label: 'Default' },
  { key: 'sponsored', label: 'Sponsored' },
  { key: 'paid', label: 'Submitted' },
]

export const getBaseLivePublicationSiteUrl = ({
  siteId,
  domainIsActive,
  domainName,
}: {
  siteId: string
  domainIsActive?: boolean
  domainName?: string
}): string => {
  if (!siteId) {
    console.error('siteId is required in getBaseLivePublicationSiteUrl')
    return `https://example-publication.com`
  }
  if (domainIsActive && domainName) {
    return `https://${domainName}`
  }
  if (window.location.origin.includes('localhost')) {
    return `http://${siteId}.localhost:3001`
  }

  if (process.env.NEXT_PUBLIC_APP_DOMAIN && window.location.origin.includes(process.env.NEXT_PUBLIC_APP_DOMAIN)) {
    return `https://${siteId}.${process.env.NEXT_PUBLIC_SITES_DOMAIN}`
  } else {
    console.error('Unable to determine base url in getBaseLivePublicationSiteUrl')
    return `https://example-publication.com`
  }
}

export const MAX_IMAGE_UPLOAD_SIZE = 10 * 1000000 // 10MB

export const changeErrorMsgFromBytesToMB = (errorMsg: string): string => {
  const errorMsgFormatted = errorMsg.replace(/File is larger than (\d+) bytes/, (match, p1) => {
    const mb = Math.round(Number(p1) / 1000000)
    return `File is larger than ${mb} MB`
  })
  return errorMsgFormatted
}

export const publicSiteChateIsOpenByDefault = true

export const getAppLogo = ({
  themePalette,
  requestedHeight,
}: {
  themePalette?: THEME_PALETTE
  requestedHeight: number
}): {
  logoSrc: any
  logoWidth: number
  logoHeight: number
} | null => {
  if (!themePalette) return null
  let logoSrc: any = ''
  const ratio = 807.77 / 171.77
  const logoWidth = ratio * requestedHeight
  if (themePalette === 'dark') {
    logoSrc = pubgenLogo_light
  } else {
    logoSrc = pubgenLogo_dark
  }
  return {
    logoSrc,
    logoWidth,
    logoHeight: requestedHeight,
  }
}

export const buildDomainForGraphQL = (workspace: string): string =>
  window ? `${workspace}.${window?.location.host}` : ''

export const HIGHLIGHTED_FEATURE_LIST = [
  'AI-Powered Content Creation',
  'Subscriber Management',
  'Monetization Optimization',
  'A.I. Image Generator',
  'CMS and Publication A.I. Chatbot',
  'Analytics',
  'Third-Party Integrations (e.g. Google Analytics, Associated Press, Mailchimp)',
]

export const getPublicationStatusForDisplay = (status: StripeSubscriptionStatus | undefined): string => {
  switch (status) {
    case 'active':
      return 'Pro Plan'
    case 'canceled':
      return 'Canceled'
    case 'incomplete':
      return 'Incomplete'
    case 'incomplete_expired':
      return 'Incomplete Expired'
    case 'past_due':
      return 'Past Due'
    case 'trialing':
      return 'Free Trial'
    case 'unpaid':
      return 'Unpaid'
    default:
      return 'Inactive'
  }
}

export const getPlanIdAndFrequencyFromPriceId = ({
  priceId,
  stripePlans,
}: {
  priceId: string
  stripePlans?: SubscriptionPlan[]
}): { planProductId: string; frequency: StripePlanFrequency } | undefined => {
  const stripePlan = stripePlans?.find(i => i.stripePriceIdMonthly === priceId || i.stripePriceIdYearly === priceId)
  if (!stripePlan) return undefined
  return {
    planProductId: stripePlan.productId,
    frequency: stripePlan.stripePriceIdMonthly === priceId ? 'monthly' : 'yearly',
  }
}

export const getPriceIdFromPlanProductIdAndFrequency = ({
  planProductId,
  frequency,
  stripePlans,
}: {
  planProductId?: string
  frequency?: StripePlanFrequency
  stripePlans?: SubscriptionPlan[]
}): string | undefined => {
  if (!planProductId || !frequency || !stripePlans) return ''
  const stripePlan = stripePlans.find(i => i.productId === planProductId)
  if (!stripePlan) return ''
  if (frequency === 'monthly') return stripePlan.stripePriceIdMonthly
  return stripePlan.stripePriceIdYearly
}

export const createStripePlansListForDropdownExposed = (
  data: GetPagePublicationSettingsQuery | undefined,
): DropdownExposedProps['items'] => {
  const availablePlans = data?.getSite?.connectedStripeAccount?.subscriptionPlans.reduce(
    (tot, curr) => {
      if (curr.stripePriceIdMonthly && curr.priceForMonthlyToggleChoice) {
        tot.push({ key: curr.stripePriceIdMonthly, label: `${curr.name} - Monthly` })
      }
      if (curr.stripePriceIdYearly && curr.priceForYearlyToggleChoice) {
        tot.push({ key: curr.stripePriceIdYearly, label: `${curr.name} - Yearly` })
      }
      return tot
    },
    [] as DropdownExposedProps['items'],
  )
  return availablePlans || []
}
