import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import type { FetchUserAttributesOutput } from 'aws-amplify/auth'
import { createContext } from 'react'

import { BrandPermissions, WorkspacePermissions } from '../hoc/withLoggedInUserPermissions'
import { OpenSnackbarType } from '../hoc/withSnackbar'
import { THEME_PALETTE } from './../hoc/withTheme'

export type AppolloClientContextType = ApolloClient<NormalizedCacheObject> | null
export const apolloClientContext = createContext<AppolloClientContextType>(null)

export type SnackbarContextType = OpenSnackbarType | (() => void)
export const snackbarContext = createContext<SnackbarContextType>(() => {})

export type ThemeContextType = {
  themePalette: THEME_PALETTE
  setThemePalette: (themePalette: THEME_PALETTE) => void
} | null
export const themeContext = createContext<ThemeContextType>(null)

export type UserContextType = FetchUserAttributesOutput | undefined
export const userContext = createContext<UserContextType>(undefined)

export type PermissionContextType = WorkspacePermissions & BrandPermissions
export const permissionsContext = createContext<PermissionContextType>({
  workspacePermissions: undefined,
  brandPermissions: undefined,
})
